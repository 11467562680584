.opt-paginator {
    color: #3f3356;
    display: flex;
    align-items: center;
    .opt-paginator__page-content {
        display: flex;
        align-items: center;
        margin: 0;
    }
    .opt-paginator__page {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        margin-left: 5px;
    }
    .opt-paginator__icon {
        font-size: 25px;
        margin: 0 15px;
        cursor: pointer;
    }
    .opt-paginator__icon--first {
        margin-right: 0;
    }
    .opt-paginator__icon--previous {
        margin-left: 5px;
    }
    .opt-paginator__icon--next {
        margin-right: 5px;
    }
}
