@import "./_variables";

/* Global styles */

* {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    @media (min-width: 960px) {
        font-size: 14px;
    }
}

/*
* Main wrapper of the app
*/
html,
body,
#opt {
    height: 100%;
}

// Todo: remove popup external lib & styles
.popupbox-content {
    padding: 1.8em 1.5em !important;
    @media (min-width: 960px) {
        min-width: 405px;
        max-width: 405px;
    }
}
.popupbox-wrapper {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
}
