.opt-empty-state {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
    svg {
        font-size: 4rem;
        color: #b5afba;
    }
    p {
        margin-top: 10px;
        max-width: 250px;
        text-align: center;
        font-weight: bold;
        color: #b5afba;
    }
}
