.opt-view--knowledge {
    height: auto !important;
    min-height: 85%;
    .opt-title {
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        .opt-view-header,
        .opt-view-filters {
            flex-wrap: wrap;
        }
        .opt-view-header__title-wrapper,
        .opt-view-filters__select {
            width: 100%;
        }
        .ReactTags__tags {
            margin: 0 !important;
            display: flex !important;
            flex-wrap: wrap !important;
        }
    }
    .knowledge-wrapper {
        width: 100%;
        @media (min-width: 768px) {
            display: grid;
            grid-template-rows: 0.85fr 0.85fr 0.85fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 20px;
        }
    }
}
.react-confirm-alert-body {
    font-size: 16px;
    h1 {
        font-size: 18px;
    }
    button {
        font-size: 16px;
    }
}
