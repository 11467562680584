.opt-view--monitoring-list {
    .opt-title {
        margin-bottom: 20px;
    }
    .view-more {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .opt-table-select-filter {
        min-width: 200px;
    }
    .opt-card--table {
        @media (min-width: 768px) and (max-width: 1366px) {
            th.ant-table-cell,
            th.ant-table-cell span,
            .ant-table-filter-column-title {
                font-size: 12px !important;
            }
        }
    }
}
.opt-view--monitoring {
    .opt-title {
        margin-bottom: 20px;
    }
    .opt-subtitle {
        margin-bottom: 10px;
    }
    // 2 cols top
    .filters-row {
        @media (min-width: 768px) {
            display: flex;
            align-content: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 50%;
        }
        .filters-col {
            width: 100%;
            margin-bottom: 35px;
            .opt-card {
                width: 100%;
            }
        }
        .filters-col--select {
            display: flex;
            flex-direction: column;
            .filters-input {
                border: 1px solid #ecebed;
                border-radius: 5px;
                padding: 10px 5px;
                margin: 5px 0;
                margin-bottom: 15px;
            }
        }
        .filters-col--tags {
            display: flex;
            flex-direction: column;
            @media (min-width: 768px) {
                margin-right: 15px;
            }
            .top-tags {
                display: flex;
                flex-wrap: wrap;
            }
            .top-tag {
                background: #f1f1fe;
                border-radius: 6px;
                color: #4c33aa;
                padding: 12px;
                margin: 5px;
                margin-left: 0;
            }
        }
        .filters-col-scores {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            .filters-col-score-group {
                display: flex;
                flex-wrap: wrap;
            }
            .score-progress-wrapper {
                width: 50%;
                padding-right: 10px;
                .opt-label-title {
                    text-transform: lowercase;
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .chat-history-row {
        margin-top: 20px;
        width: 100%;
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
        }
        .chat-history-wrapper {
            padding: 5px;
        }
        .chat-history {
            max-height: 75vh;
            overflow: auto;
            padding: 20px;
        }
        .chat-history-col {
            width: 100%;
            @media (min-width: 768px) {
                width: 48%;
            }
        }
        .chat-analysis {
            max-height: 55vh;
            overflow: auto;
            padding: 20px;
            .chat-analysis__title {
                display: block;
                color: #3f3356;
                font-size: 14px;
                font-weight: bold;
                margin: 10px 0;
                margin-top: 5px;
            }
            .chat-analysis__selected {
                display: block;
                color: rgba(253, 106, 86, 1);
                margin-bottom: 15px;
            }
            .chat-analysis__text {
                margin-bottom: 20px;
            }
            .chat-analysis__questions {
                padding-left: 25px;
            }
            .chat-analysis__question {
                margin: 10px 0;
                font-size: 16px;
                list-style-type: disc;
            }
        }
        .chat-analysis--not-found {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            .chat-analysis__not-found-text {
                color: #c8c1ce;
                font-weight: bold;
                text-align: center;
                cursor: default;
            }
        }
    }
    .chat-history-row *::-webkit-scrollbar {
        width: 5px;
    }

    .chat-history-row *::-webkit-scrollbar-track {
        background: #dadada;
    }

    .chat-history-row *::-webkit-scrollbar-thumb {
        background: #b8b8b8;
    }
    .profile-box--tags {
        .profile-box__avatar {
            margin-bottom: 0;
            margin-top: 35px;
        }
    }
}
