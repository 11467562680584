.opt-modal-wrapper {
    background-color: rgba(36, 36, 36, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .opt-modal {
        width: 90%;
        border-radius: 5px;
        background-color: white;
        padding: 1em;
        @media (min-width: 768px) {
            width: 700px;
        }
    }
    .opt-modal--medium {
        @media (min-width: 768px) {
            width: 500px;
        }
    }
    .opt-modal--small {
        @media (min-width: 768px) {
            width: 350px;
        }
    }
    .opt-modal__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .opt-button {
            font-size: 16px;
            margin-left: 20px;
            max-width: 120px;
        }
    }
}
