.opt-view--home {
    .opt-view-header {
        display: flex;
        border-bottom: 2px solid #35209e;
    }
    .opt-home-stats,
    .opt-channels {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .opt-card--stat {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        @media (min-width: 960px) {
            width: 50%;
        }
        @media (min-width: 1300px) {
            width: 18%;
            margin-bottom: 0;
        }
        .opt-card__stat-title {
            margin: 0;
            font-size: 1rem;
            min-height: 50px;
        }
        .opt-card__stat {
            font-size: 2.5rem;
            margin: 0;
        }
    }

    .opt-channels {
        justify-content: space-around;
    }
    .opt-card--channel,
    .opt-card--integration {
        text-align: center;
        width: 100%;
        margin-bottom: 12px;
        position: relative;
        @media (min-width: 1300px) {
            width: 30%;
            margin: 0;
        }
        @media (min-width: 1700px) {
            width: 25%;
        }
        .opt-text,
        .opt-card__integration-title,
        .opt-card__channel-title {
            margin-bottom: 0;
        }
        .opt-card__actions-wrapper {
            display: flex;
            align-items: center;
        }
    }
    .opt-card--channel-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1em;
    }
    .opt-card__channel-icon,
    .opt-card__integration-icon {
        font-size: 3rem;
    }
    .opt-card__channel-icon.chat {
        color: #5aa7f4;
    }
    .opt-card__channel-icon.whatsapp {
        color: #25d366;
    }
    .opt-card__channel-icon.facebook {
        color: #3b5998;
    }
    .opt-card__channel-icon.webchat {
        color: #92d3ff;
    }
    .opt-card__channel-icon.sms {
        color: #5c4ab2;
    }
    .opt-card__channel-icon.instagram {
        color: white;
        background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
        );
        background: -webkit-radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
        );
        border-radius: 10px;
    }
    .opt-card__channel--activated {
        font-weight: bold;
        color: #38c976;
    }
    .opt-button {
        min-height: auto !important;
    }
    .opt-button--copy {
        max-width: 200px !important;
        span {
            margin-right: 5px;
        }
    }
    .home-modal-view--channel {
        text-align: center;
    }
    .home-modal-view--table {
        td,
        th {
            padding: 16px 0;
        }
    }
    .home-modal-view--code {
        .opt-code-examples {
            max-height: 400px;
            overflow: auto;
        }
    }
    .opt-button--token {
        margin-right: 5px;
    }
    .opt-button--success {
        margin-bottom: 0;
    }

    .onboarding-step {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fef4f1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fd6a56;
        border: 1px solid #fd6a56;
        cursor: default;
    }
}
