.ViewMore {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}
.ViewMoreText {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #3f3356;
    cursor: pointer;
}
