// paths
$componentAssetPath: "../../img";
// Colors
$primary-orange: #fd6a56;
$primary-violet: #35209e;
$secondary-orange: #fd6a56;
$secondary-violet: #7752db;
$dark-violet: #3f3356;
$light-orange: #fef4f1;
$grey: #afa8b4;
// Fonts
$mobile-title: 2.6rem;
$mobile-subtitle: 1.5rem;
