.agent-box {
    display: flex;
    flex-wrap: wrap;
}
.agent-box__data {
    width: 100%;
    .profile-box__avatar:nth-of-type(1) {
        margin-bottom: 0;
    }
    &:nth-of-type(2) {
        margin-top: 20px;
    }
}
