.opt-view--skills {
    .opt-skills-tab {
        max-width: 500px;
    }
    .opt-card--skills {
        margin-bottom: 50px;
    }
    .opt-card__header {
        display: flex;
        .opt-checkbox-group {
            margin-left: auto;
        }
    }
    .opt-input-group {
        margin-bottom: 5px;
    }
    .CodeMirror {
        height: 600px;
    }
}
.opt-skills-tab--lead {
    .opt-card--skills:nth-of-type(2) {
        margin-right: 15px;
    }
}
.opt-skills-tab--lead,
.opt-skills-tab--general {
    display: flex;
    flex-wrap: wrap;
    &.opt-skills-tab {
        max-width: 100%;
    }
    .opt-card--skills {
        min-width: 45%;
        max-width: 45%;
    }
    .opt-card--skills:nth-of-type(1) {
        margin-right: 15px;
    }
}
.opt-skills-tab--routing {
    display: flex;
    flex-wrap: wrap;
}
.opt-card--xml {
    width: 100%;
}
