.opt-icon-button {
    background: none;
    background-color: none;
    border-radius: 50%;
    box-shadow: none;
    border: none;
    cursor: pointer;

    svg {
        color: #4e3caa;
    }

    &:disabled svg {
        color: #1111;
        cursor: not-allowed;
    }
}
