.opt-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100vh;
    * {
        font-size: 16px;
    }
}

.dashboard-logo {
    max-width: 55%;
}

/* Views wrapper */
.opt-view {
    padding: 20px 30px;
    height: 85%;
    @media (min-width: 1500px) {
        padding: 50px;
        padding-right: 200px;
    }
    .opt-title,
    .opt-title * {
        color: #35209e;
        font-size: 18px;
    }
    .opt-text {
        font-size: 16px;
    }
    .opt-title--link {
        cursor: pointer;
    }
    .opt-view-header {
        .opt-title {
            margin-bottom: 0px;
        }
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        .opt-view-header__actions {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .date-picker {
                margin-left: 20px;
            }
            .opt-button {
                font-size: 16px;
                margin: 10px;
                white-space: nowrap;
            }
            .opt-button:last-child {
                margin: 0;
            }
            .filters {
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
            }
            .filter {
                margin-left: 15px;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                &.active {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }
    }
    .opt-view-header__filters {
        display: flex;
        margin-bottom: 15px;
        border-color: red;
    }
    .opt-subtitle {
        color: #3f3356;
    }
    .opt-label-title {
        font-weight: 600;
        font-size: 15px;
        color: #3f3356;
    }
    .opt-button {
        cursor: pointer;
    }
    .opt-text-input {
        width: 100%;
        border: 1px solid #c0c0c0;
        border-radius: 6px;
        padding: 10px;
        margin: 5px 0;
        font-size: 16px;
        outline: none;
        color: #3f3356;
        &:focus {
            outline: 1px solid #88c0f5;
        }
        &--icon {
            padding-right: 38px;
        }
    }
    .opt-select-input {
        width: 100%;
        border: 1px solid #ecebed;
        border-radius: 6px;
        margin: 5px 0;
        padding: 10px 0;
        font-size: 14px;
        outline: none;
        color: #3f3356;
        &:focus {
            outline: 1px solid #88c0f5;
        }
        &--icon {
            padding-right: 38px;
        }
    }
    .opt-textarea {
        min-height: 100px;
    }
    .opt-form-group {
        .label-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }
    .button-group {
        cursor: pointer;
        border: 1px solid #fd6a56;
        font-weight: 300;
        font-size: 1.4rem;
        text-align: center;
        margin-top: 1em;
        border: none;
        color: white;
        box-shadow: 0px 6.44267px 6.44267px rgba(53, 32, 158, 0.1);
        border-radius: 6px;
        button {
            background: none;
            padding: 0.3em 0.5em;
            border: none;
            color: #35209e;
            cursor: pointer;
        }
        .button-group__left {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border: 1px solid #35209e;
        }
        .button-group__right {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border: 1px solid #35209e;
            border-left: none;
        }
        .active {
            background-color: #35209e;
            color: white;
        }
    }
    .opt-view-filters {
        display: flex;
        align-items: center;
    }
    .opt-view-tags-filter {
        display: flex;
        width: 180px;
        margin-left: 5px;
        flex-direction: column;
    }
    /*
    * React tags edits
    */
    .opt-view-keywords-filter {
        display: flex;
        justify-content: flex-end;
        .tag-wrapper.ReactTags__tag {
            display: flex;
            white-space: nowrap;
            align-items: center;
        }
        .ReactTags__selected {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;
        }
        .ReactTags__tagInput {
            margin-right: 5px;
            margin-bottom: 0;
            input {
                display: block;
                min-width: 165px;
                max-width: 165px;
                padding: 20px 5px;
            }
        }
    }
}

.opt-card {
    box-shadow: 3px 3px 15px rgba(135, 135, 135, 0.15);
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.opt-card__pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.opt-card--table {
    overflow-y: auto;
    .ant-pagination {
        margin-right: 5px !important;
    }
}

.opt-card--no-padding {
    padding: 0;
}

.menu-icon {
    position: fixed;
    /* Needs to stay visible for all mobile scrolling */
    display: flex;
    top: 5px;
    left: 10px;
    align-items: center;
    justify-content: center;
    background-color: #dadae3;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
    padding: 12px;
}

.sidenav {
    grid-area: sidenav;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: all 0.6s ease-in-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    z-index: 2;
    /* Needs to sit above the hamburger menu icon */
    background-color: #35209e;
    @media (min-width: 1400px) {
        width: 240px;
    }
}

.sidenav.active {
    transform: translateX(0);
}

.sidenav__logo {
    color: white;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidenav__close-icon {
    position: absolute;
    visibility: visible;
    top: 8px;
    right: 12px;
    cursor: pointer;
    font-size: 20px;
    color: #ddd;
}

.sidenav__list {
    padding: 0;
    list-style-type: none;
}

.sidenav__list-menu {
    position: relative;
    padding: 12px 20px 12px 40px;
    @media (min-width: 1480px) {
        padding: 20px 20px 20px 40px;
    }
    color: #ddd;
    display: block;
}

.sidenav__list-menu:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidenav__list-menu::before {
    content: '▶';  /* Default: closed state */
    position: absolute;
    left: 10px; /* Adjust as needed */
    transition: transform 0.3s ease;
}

.sidenav__list-menu.open::before {
    content: '▼'; /* Open state */
}

.sidenav__list-item {
    position: relative;
    padding: 12px 20px 12px 40px;
    @media (min-width: 1480px) {
        padding: 20px 20px 20px 40px;
    }
    color: #ddd;
    display: block;
}

.sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.sidenav__list-item--active {
    background-color: rgba(255, 255, 255, 0.2);
}

.sidenav__submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    padding-left: 20px; /* To give padding to submenu */
}

.sidenav__submenu.open {
    max-height: 300px; /* Adjust to the maximum potential height of your submenu */
}

.logout-button {
    margin: auto 20px 20px 20px;
    padding: 8px;
    background-color: transparent;
    color: white;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid white;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: white;
        color: #35209e;
    }
}

.main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
    .opt-grid-container {
        grid-template-columns: 200px 1fr;
        grid-template-areas: "sidenav main";
    }

    .sidenav {
        position: relative;
        transform: translateX(0);
    }

    .sidenav__close-icon {
        visibility: hidden;
    }

    .main {
        width: auto;
        overflow: auto;
    }
}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {
    .main-cards {
        column-count: 2;
    }
}

@media (min-width: 1400px) {
    .opt-grid-container {
        grid-template-columns: 240px 1fr;
        grid-template-areas: "sidenav main";
    }
}

.opt-table {
    width: 100%;
    text-align: left;
    @media (max-width: 768px) {
        overflow-y: scroll;
        th,
        td {
            padding: 25px 40px 25px 0;
        }
        td:nth-of-type(1),
        th:nth-of-type(1) {
            padding-right: 40px;
        }
        td:last-child,
        th:last-child {
            padding-left: 40px;
            padding-right: 20px;
        }
    }
    th {
        color: #3f3356;
        padding-bottom: 15px;
    }
    td {
        color: #a29ca8;
        padding: 25px 0;
        border-bottom: 1px solid #f4f4f4;
    }
    td:nth-of-type(1) {
        color: #fd6a56;
        font-weight: bold;
    }
    td:nth-of-type(2) {
        color: #3f3356;
        font-weight: bold;
    }
    tr td:last-child {
        border-bottom: 0px;
    }
    .align-right {
        text-align: right;
    }
    .opt-table__button {
        background: none;
        border-radius: 50%;
        box-shadow: none;
        padding: 0.4em;
        margin: 0 0.4em;
        border: none;
        cursor: pointer;
        svg {
            color: #4e3caa;
        }
    }
    .opt-table__button--red {
        svg {
            color: #fd6a56;
        }
    }
}

/*
* Not found
*/
.opt-view--not-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .not-found {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        .not-found-text {
            color: #c8c1ce;
            font-weight: bold;
            text-align: center;
            cursor: default;
        }
    }
}

/**
* Scroll on cards
**/
.opt-card::-webkit-scrollbar,
.opt-card *::-webkit-scrollbar {
    width: 5px;
}

.opt-card::-webkit-scrollbar-track,
.opt-card *::-webkit-scrollbar-track {
    background: #dadada;
}

.opt-card::-webkit-scrollbar-thumb,
.opt-card *::-webkit-scrollbar-thumb {
    background: #b8b8b8;
}

/**
* View More
**/
.view-more {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    .view-more__text {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #3f3356;
        cursor: pointer;
    }
}

/**
* Pre tag code viewer
**/
.opt-code-examples {
    background-color: #e4e4e4;
    border-radius: 10px;
    padding: 5px 15px;
}

/*
* Global ReactTags styling
*/
.opt-view {
    div.ReactTags__tags {
        position: relative;
        margin-left: 12px;
    }

    div.ReactTags__tagInput input.ReactTags__tagInputField,
    div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
        height: 16px;
        margin: 0;
        font-size: 12px;
        width: 100%;
        border: 1px solid #eee;
    }

    /* Styles for selected tags */
    .ReactTags__selected {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .ReactTags__tagInput {
        width: 100%;
        margin-bottom: 5px;
        order: -1;
    }
    .ReactTags__tagInput input {
        border: 1px solid #ecebed;
        border-radius: 5px;
        padding: 15px 5px;
    }
    .ReactTags__tagInput input:focus {
        outline: 2px solid #ecebed;
    }
    div.ReactTags__selected span.ReactTags__tag {
        border: 1px solid #ddd;
        background: #eee;
        font-size: 12px;
        display: inline-block;
        padding: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 2px;
    }

    div.ReactTags__selected a.ReactTags__remove {
        color: #aaa;
        margin-left: 5px;
        cursor: pointer;
    }

    /* Styles for suggestions */
    div.ReactTags__suggestions {
        position: absolute;
    }

    div.ReactTags__suggestions ul {
        list-style-type: none;
        box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
        background: white;
        width: 200px;
    }

    div.ReactTags__suggestions li {
        border-bottom: 1px solid #ddd;
        padding: 5px 10px;
        margin: 0;
    }

    div.ReactTags__suggestions li mark {
        text-decoration: underline;
        background: none;
        font-weight: 600;
    }

    div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
        background: #b7cfe0;
        cursor: pointer;
    }
}
/*
* Popover
*/
.opt-popover-wrapper-content {
    max-width: 300px;
}
.opt-popover-text {
    font-size: 15px;
}
.opt-popover-img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
.opt-popover-example-img {
    max-width: 100%;
    border-radius: 10px;
}
// Move this into TitleTooltip.js
.opt-title-tooltip {
    font-size: 15px;
    svg path {
        color: #b5afba !important;
    }
}
.opt-title-tooltip-content {
    display: block;
    font-size: 15px;
    max-width: 300px;
}
.instagram-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 1px 3.2px;
    margin: 0;
    color: #fff;
    background: #d6249f;
    background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
    );
    * {
        margin: 0 !important;
    }
}

.whatsapp {
    color: #25d366;
}
