$link-color: rgb(0, 157, 226);

.whatsapp-background-image {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 20rem;
    max-height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}

.whatsapp-message-inner-wrapper {
    width: 90%;
    height: auto;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    overflow-wrap: break-word;
}

.ws-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $link-color;
    border-top: solid;
    border-color: #dadcdd;
    border-width: 0.01rem;
    margin-top: 0.5rem;
    padding-top: 0.4rem;
}

.quick-reply-button {
    width: 90%;
    height: auto;
    background-color: white;
    border-radius: 1rem;
    padding: 0.4rem 0 0.4rem;
    overflow-wrap: break-word;
    margin-top: 0.15rem;
    display: flex;
    justify-content: center;
    color: $link-color;
    font-weight: 500;
}
