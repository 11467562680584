// App includes
@import "./_views";
@import "./popupbox";
@import "./FormComponents";

* {
    box-sizing: border-box;
}

.main-container {
    display: flex;
    flex-direction: column;
}
:root {
    /* Colors */
    --opt-blue: #5aa7f4;
    --opt-light-blue: #8dc5f5;
    --opt-red: rgb(253, 106, 86);
    --opt-light-red: rgba(253, 106, 86, 0.4);
    --opt-grey: #505050;
    --opt-light-grey: #dadada;
    --opt-green: #4ee29b;
    --opt-text-color: #3f3356;
    /* Sizes */
    --opt-desktop-width: 380px;
    --opt-desktop-height: 580px;
}
#optiwe_dash {
    height: 100%;
}

/*
* Ant design overrides
* Todo: load theme properly with variables
*/
.ant-select * {
    font-size: 16px;
}
.ant-select-dropdown * {
    font-size: 15px;
}
.ant-notification {
    .anticon svg:nth-of-type(1) {
        font-size: 1.8rem;
    }
}
.ant-alert .anticon svg {
    font-size: 25px;
}
.ant-alert .anticon-close svg {
    font-size: 10px;
}
