.chat-history {
    .chat-history__message--assistant {
        text-align: right;
    }
    .chat-history__from {
        display: block;
        color: #3f3356;
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        margin-top: 5px;
    }
    .chat-history__chat-bubble {
        display: inline-block;
        width: auto;
        margin-bottom: 0.6em;
        word-break: break-all;
        padding: 0.8em 1em;
        box-shadow: 3px 3px 15px 0 rgba(135, 135, 135, 0.15);
        font-size: 15px;
        line-height: 1.54;
    }
    .chat-history__message--assistant {
        .chat-history__chat-bubble {
            border: 1px solid #fd6a56;
            background-color: white;
            color: #3f3356;
            border-radius: 15px 3px 15px 15px;
            cursor: pointer;
        }
        .chat-history__chat-bubble--selected {
            background-color: #fd6a56;
            color: white;
        }
    }
    .chat-history__message--end-user {
        .chat-history__chat-bubble {
            background-color: white;
            color: #3f3356;
            border-radius: 3px 15px 15px 15px;
            cursor: default;
        }
    }
}
