.opt-main-loader {
    position: fixed;
    @media (min-width: 768px) {
        position: absolute;
    }
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    animation: opt-main-loader-fade 0.1s forwards;
    .opt-main-loader__load {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 0.25em solid rgba(0, 0, 0, 0.1);
        border-top-color: #5aa7f4;
        border-radius: 50%;
        animation: opt-main-loader-spin 0.8s infinite linear;
        margin: 2em;
    }
}
@keyframes opt-main-loader-spin {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes opt-main-loader-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
