.ant-list-item.list-item-change-ws {
    opacity: 0.8;
    transition: 0.4s;
}

.ant-list-item.list-item-change-ws:hover {
    cursor: pointer;
    opacity: 1;
}

.ant-list-item.disabled-list-item-change-ws {
    opacity: 0.8;
    transition: 0.4s;
    color: #888;
    background-color: #f1f1f1;
}

.ant-list-item.disabled-list-item-change-ws:hover {
    cursor: not-allowed;
    opacity: 1;
}

.login-icon {
    max-width: 30%;
    border: none;
    font-size: 200%;
}

.disabled-login-icon {
    cursor: not-allowed;
    max-width: 30%;
    border: none;
    font-size: 200%;
    color: #888;
    background-color: #f1f1f1;
}