/*
* TODO: Remove this duplicated file
* Check where is loaded to avoid errors
*/
@import "../../styles/variables";
.opt-text-input {
    width: 100%;
    border: 1px solid #ecebed;
    border-radius: 6px;
    height: 36px;
    padding: 1.5rem;
    margin-top: 1em;
    font-size: 16px;
    outline: none;
    color: $dark-violet;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.opt-button {
    width: 100%;
    background-color: $primary-orange;
    font-weight: 300;
    font-size: 16px;
    padding: 0.5em 0.8em !important;
    text-align: center;
    margin-top: 1em;
    min-height: 45px;
    border: none;
    color: white;
    border-radius: 6px;
    box-shadow: none;
    position: relative;
    cursor: pointer;
    a {
        display: inline-block;
        color: white;
        width: 100%;
        height: 100%;
    }
    &:disabled {
        box-shadow: 0px 0px 0px rgba(253, 106, 86, 0.25);
        background-color: #e9dff2;
        cursor: default;
    }
    &:hover:disabled {
        box-shadow: 0px 0px 0px rgba(253, 106, 86, 0.25);
    }
    .opt-button__link-icon {
        position: absolute;
        right: 15px;
        top: 14px;
    }
}
.opt-button--light {
    background-color: $light-orange;
    color: $secondary-orange;
    box-shadow: none;
    a {
        color: $secondary-orange;
    }
}
