.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 100px;
    align-items: center;
}

.header-logo {
    max-height: 50px;
}

.header-title {
    color: white;
    font-size: 18px;
}
