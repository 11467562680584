.opt-view--form {
    .knowledge-header {
        display: flex;
        align-items: center;
        .opt-button {
            max-width: 200px;
            margin-left: auto;
        }
    }
    @media (min-width: 1300px) {
        .knowledge-header,
        form {
            max-width: 1200px;
        }
        .card-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            .basic-info {
                width: 48%;
                margin-right: 4%;
            }
            .basic-info:nth-of-type(2) {
                margin-right: 0;
            }
        }
    }
    .opt-subtitle {
        margin-bottom: 20px;
    }
    .opt-card--form {
        width: auto;
        //max-width: 550px;
    }
    .knowledge-card-info {
        margin-bottom: 30px;
    }
    .opt-form-group {
        margin-bottom: 10px;
    }
    .opt-form-text {
        font-size: 14px;
        color: #3f3356;
        display: block;
        margin-top: 8px;
    }
    .opt-form-text {
        color: grey;
    }
    .opt-input-button {
        display: flex;
        align-items: center;
        button {
            max-width: 20%;
            margin: 0;
            padding: 0px 6px !important;
            margin-left: 10px;
            font-size: 14px;
            background-color: white;
            border: 1px solid #35209e;
            color: #35209e;
            box-shadow: 0px 6.44267px 6.44267px rgba(53, 32, 158, 0.1);
        }
    }
    .opt-button {
        min-height: 36px !important;
    }
    .opt-select {
        min-height: 42px;
    }
    .opt-select-option::first-letter {
        text-transform: capitalize;
    }
    .opt-knowledge-questions {
        margin-top: 15px;
        .edit-knowledge {
            position: relative;
            cursor: pointer;
            .opt-delete-button {
                position: absolute;
                right: 15px;
                top: 50%;
                color: #fd6a56;
            }
        }
    }
    .opt-knowledge-questions-wrapper {
        padding-left: 25px;
        border-left: 1px solid #fd6a56;
    }
    .opt-form-group--send {
        display: flex;
        justify-content: flex-end;
        button {
            border: 1px solid #fd6a56;
        }
        button,
        input[type="button"] {
            font-size: 15px;
            margin-top: 10px;
            max-width: 200px;
            margin-left: 20px;
        }
    }
}
/*
* Popovers
*/
.opt-popover--question {
    max-width: 280px !important;
}
