@import "../../styles/_variables";
/* Login */
.section-login {
    min-height: 100vh;
    .section-login__logo {
        min-height: 40vh;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: center;
        background-color: $primary-violet;
        padding: 0 0.5em;
        @media (min-width: 700px) {
            align-content: center;
        }
        &:before {
            content: "";
            width: 70%;
            height: 184px;
            background-image: url(../../img/background_login-2.svg);
            background-position: top left;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        @media (max-width: 448px) {
            &:after {
                content: "";
                width: 100%;
                height: 200px;
                background-image: url(../../img/background_login.svg);
                background-position: bottom;
                background-size: cover;
                position: absolute;
                bottom: -9em;
            }
        }
        h1 {
            text-align: center;
            span {
                display: none;
            }
            z-index: 3;
        }
        img {
            max-width: 65%;
            height: auto;
        }
        p {
            width: 100%;
            font-size: 1.8rem;
            margin: 1.5em 1em 2.5em 1em;
            color: #fff;
            text-align: center;
            z-index: 2;
        }
    }
    .section-login__form {
        width: 100%;
        padding: 14em 2em 0 2em;
        button a {
            font-size: 1.4rem !important;
        }
        @media (min-width: 700px) {
            max-width: 500px;
            margin: 0px auto;
        }
        .section-login__input {
            width: 100%;
            border: 1px solid #ecebed;
            border-radius: 6px;
            height: 36px;
            padding: 1.5em;
            margin-top: 1em;
            font-size: 1.3rem;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
        }
        .section-login__submit {
            width: 100%;
            background-color: $primary-orange;
            padding: 0.5em;
            text-align: center;
            font-weight: bold;
            font-size: 1.7rem;
            margin-top: 1em;
            border: none;
            color: white;
            box-shadow: 0px 6.44267px 6.44267px rgba(253, 106, 86, 0.25);
            border-radius: 6px;
            &:disabled {
                box-shadow: 0px 0px 0px rgba(253, 106, 86, 0.25);
                background-color: #e9dff2;
            }
        }
    }
}
