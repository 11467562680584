.opt-view--users-list {
    height: 85%;
    .user-modal-title {
        margin-bottom: 1em;
    }
    .opt-form-group {
        margin-bottom: 1em;
    }
    .opt-list {
        padding-left: 2em;
        li {
            list-style: circle;
        }
    }
    .opt-input-group {
        display: flex;
        align-items: center;
    }
}
