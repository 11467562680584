.opt-settings-tab {
    max-width: 300px;
}
.opt-settings-tab--general {
    @media (min-width: 768px) {
        min-width: 400px;
    }
}
.opt-card--settings {
    margin-bottom: 50px;
    .opt-label-title {
        display: block;
    }
    .opt-label-title:nth-of-type(2) {
        margin-top: 15px;
    }
}
.opt-card--settings.chat-settings {
    .opt-subtitle:nth-of-type(2) {
        margin-top: 15px;
    }
}
.opt-settings-tab--icons {
    display: flex;
    .opt-icon-settings {
        min-width: 300px;
        margin-right: 15px;
    }
}
.opt-settings-tab--integrations {
    display: flex;
    flex-direction: column;
    @media (min-width: 900px) {
        flex-direction: row;
    }
    .opt-card {
        @media (min-width: 900px) {
            min-width: 400px;
            margin-right: 15px;
        }
        .opt-card__header {
            display: flex;
            align-items: center;
        .opt-checkbox-group {
                margin-left: auto;
            }
            .opt-card-icon-settings {
                margin-right: 10px;
                font-size: 22px;
                align-self: normal;
            }
            .whatsapp {
                color: #25d366;
            }
            .facebook {
                color: #3b5998;
            }
            .instagram {
                color: white;
                background: radial-gradient(
                    circle at 30% 107%,
                    #fdf497 0%,
                    #fdf497 5%,
                    #fd5949 45%,
                    #d6249f 60%,
                    #285aeb 90%
                );
                background: -webkit-radial-gradient(
                    circle at 30% 107%,
                    #fdf497 0%,
                    #fdf497 5%,
                    #fd5949 45%,
                    #d6249f 60%,
                    #285aeb 90%
                );
                border-radius: 6px;
            }
            .webchat {
                color: #92d3ff;
            }
        }
    }
    .checkbox-title {
        margin-top: 20px;
        padding-top: 10px;
        border-top: 1px solid #aaaaaa;
    }
    .enableChannel-check {
        margin-bottom: 10px;
    }
    .ant-select-selection-item * {
        font-size: 12px !important;
    }
    .opt-time-settings {
        display: flex;
    }
    .opt-timezone-settings {
        margin-left: 5px;
        .ant-input-number-input {
            height: 33px;
        }
    }
}
.opt-settings-tab--chat-ui {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: 900px) {
        .opt-card {
            min-width: 300px;
            margin-right: 30px;
        }
    }
}
/*
* Input color:
*/
.opt-input-color-picker {
    display: flex;
    align-items: center;
}
.opt-color-picker {
    height: 35px !important;
    margin-left: 10px;
}
/*
* UI Chat
*/
.opt-start-chat {
    border-radius: 50%;
    background-color: #5aa7f4;
    border: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    &:hover {
        cursor: pointer;
        background-color: darken(#5aa7f4, 20%);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    &:active {
        box-shadow: none;
    }
    .opt-start-chat__icon {
        color: white;
        font-size: 3rem;
    }
}
.opt-start-chat--facebook {
    background-color: #4267b2 !important;
}
.opt-icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 30px;
    right: 30px;
}
.opt-chat-custom-icon {
    cursor: pointer;
}
.opt-icons--collapsible {
    flex-direction: column;
    .opt-chat-custom-icon {
        display: none;
        opacity: 0;
    }
    .opt-chat-icon {
        display: none;
        opacity: 0;
    }
    .opt-start-chat-toggle {
        display: block;
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.opt-icons--collapsible-open {
    .opt-chat-icon {
        display: block;
        animation: fadeInUp 0.5s forwards;
    }
    .opt-chat-custom-icon {
        display: block;
        animation: fadeInUp 0.5s forwards;
    }
    .opt-chat-icon:nth-of-type(2) {
        animation-delay: 0.1s;
    }
    .opt-chat-icon:nth-of-type(1) {
        animation-delay: 0.2s;
    }
}
.opt-ui-example {
    .opt-ui-example--container {
        min-width: 250px;
        margin-right: 25px;
    }
    .opt-embedded-views {
        width: 100%;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        background-color: white;
        border-radius: 10px;
        z-index: 2147483647;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        .opt-embedded-views__header {
            height: 50px;
            background-color: #5aa7f4;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            .opt-embedded-views__header-icon {
                color: white;
            }
            .opt-embedded-views__header-close {
                max-width: 30px;
                margin-left: auto;
                cursor: pointer;
            }
            .opt-embedded-views__title {
                color: white;
                font-size: 18px;
                margin: 0 !important;
                margin-left: 10px !important;
                font-weight: bold;
                font-family: "Roboto", sans-serif;
            }
        }
        .opt-embedded-content {
            position: relative;
            width: 100%;
            padding: 10px 15px;
        }
        .chat-history {
            padding: 10px 15px;
            .chat-send-messages {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 50px;
            }
            .chat-send-input {
                border: 1px solid #ecebed;
                border-radius: 16px !important;
                outline: none;
                padding: 5px;
            }
            .chat-send-button {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #5aa7f4;
                width: 30px;
                height: 30px;
                margin-left: 10px;
                cursor: pointer;
            }
            .chat-send-icon {
                color: white;
            }
        }
    }
}
.rc-virtual-list-scrollbar-show {
    display: block !important;
}
