.optiwe-box {
    p {
        margin: 0;
    }
    background-color: #f1f1fe;
    min-height: auto;
    padding: 10px 10px;
    margin-bottom: 8px;
    overflow: hidden;
    border-radius: 5px;
    min-height: 150px;
    .opt-app__recomendation-header {
        display: flex;
    }

    .opt-app__title {
        font-size: var(--max-font);
        font-weight: bold;
        color: #fd6a56;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 75%;
        margin-bottom: 15px !important;
    }
    .opt-app__recomendation-actions {
        display: flex;
        width: 25%;
        justify-content: flex-end;
        margin-left: auto;
        svg {
            width: 15px;
            margin-left: 10px;
            color: var(--opt-text-color);
            cursor: pointer;
        }
        svg:hover {
            color: #7752db;
        }
        svg.delete-icon {
            color: #fd6a56;
        }
    }
    .opt-app__recomendation-content {
        .opt-app__text {
            max-height: 83px;
            overflow: hidden;
            column-width: 100vw;
        }
    }
    .opt-app__recomendation-id {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        .opt-app__text {
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
        }
    }
}
