.profile-box {
    .opt-title,
    .opt-label,
    .opt-subtitle {
        margin-bottom: 0 !important;
    }
    .profile-box__avatar {
        margin-bottom: 35px;
    }
    .profile-box__avatar,
    .profile-box__more-info {
        display: flex;
    }
    .profile-box__more-info {
        .profile-box__info {
            display: flex;
            width: 100%;
            .profile-box__dates:nth-of-type(2) {
                margin-left: auto;
                text-align: right;
            }
        }
    }
    .profile-box__icon-wrapper {
        background-color: grey;
        border-radius: 50%;
        padding: 15px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .profile-box__icon {
        font-size: 20px;
        color: white;
    }
    .profile-box__info {
        margin-left: 15px;
    }
    .profile-box__last-update {
        margin-left: auto;
        text-align: right;
    }
    .profile-box__more-info {
        margin-top: 15px;
    }
}
