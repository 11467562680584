@import "./variables";
.opt-text-input {
    width: 100%;
    border: 1px solid #ecebed;
    border-radius: 6px;
    height: 36px;
    padding: 1.5em;
    margin-top: 1em;
    font-size: 16px;
    outline: none;
    color: $dark-violet;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.opt-button {
    width: 100%;
    background-color: $primary-orange;
    font-weight: 300;
    font-size: 16px;
    padding: 0.8em;
    text-align: center;
    margin-top: 1em;
    border: none;
    color: white;
    border-radius: 6px;
    transition: all 0.3s;
    a {
        display: inline-block;
        color: white;
        width: 100%;
        height: 100%;
    }
    &:disabled {
        box-shadow: 0px 0px 0px rgba(253, 106, 86, 0.25);
        background-color: #e9dff2;
    }
    &:hover {
        box-shadow: 0px 6.44267px 6.44267px rgba(253, 106, 86, 0.25);
    }
}
.opt-button--light {
    background-color: $light-orange;
    color: $secondary-orange;
    box-shadow: none;
    a {
        color: $secondary-orange;
    }
}
.opt-button--light-success {
    background-color: rgba(90, 167, 244, 0.8);
}
.opt-button--violet {
    background-color: $secondary-violet;
    box-shadow: 0px 6.44267px 6.44267px rgba(53, 32, 158, 0.23);
}
.opt-button--success {
    background-color: #38c976 !important;
    color: white;
    a {
        color: $secondary-orange;
    }
}
.opt-button--read-only {
    &:hover {
        cursor: default !important;
        box-shadow: none !important;
    }
}
