@import "../../styles/_variables";
.signup {
    background-image: url("../../img/topblueshape.svg"), url("../../img/bottomblueshape.svg");
    background-position: right top, left bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 966px) {
        height: 100%;
    }
    .signup__form {
        width: 100%;
        padding: 2em;
        @media (min-width: 966px) {
            width: 500px;
        }
    }
    .signup__title {
        font-size: $mobile-title;
        color: $dark-violet;
        margin-bottom: 0.2rem;
    }
    .signup__subtitle {
        font-size: $mobile-subtitle;
        color: $dark-violet;
        margin-bottom: 0.2rem;
    }
    .signup__text {
        color: $dark-violet;
        margin-top: 1em;
        a {
            color: $primary-orange;
            font-weight: bold;
        }
    }
    .signup__terms {
        span,
        a {
            font-size: 1.2rem;
        }
    }
}
.signup__form--success {
    text-align: center;
    p {
        font-size: 1.2rem;
    }
    .signup__icon {
        svg {
            font-size: 4rem;
            color: $dark-violet;
        }
    }
}
